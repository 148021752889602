<template>
    <van-list
        v-model:loading="loading"
        :finished="finished"
        v-model:error="error"
        :loading-text="$t('loading')"
        :finished-text="$t('load_finished')"
        :error-text="$t('load_error')"
        @load="onLoad"
    >
        <div class="container">
            <div class="trade_list">
                <div class="itemImg" @click="detail(item.p_id)" v-for="(item, index) in list1" v-bind:key="index">
                    <!--        Item card with 1 image    -->
                    <div class="oneImage" v-if="item.goods.length == 1">
                        <img :src="item.goods[0].img"/>
                    </div>
                    <!--        Item card with 2 image    -->
                    <div class="twoImage" v-if="item.goods.length == 2">
                        <div class="left">
                            <img :src="item.goods[0].img ? item.goods[0].img : defaultGoodsImg"/>
                        </div>
                        <div class="right">
                            <img :src="item.goods[1].img"/>
                        </div>
                    </div>
                    <!--        Item card with 3 image    -->
                    <div class="threeImage" v-if="item.goods.length == 3">
                        <img class="left-img" :src="item.goods[0].img"/>
                        <div class="right-img-box">
                            <img class="right-img" :src="item.goods[1].img"/>
                            <img class="right-img" :src="item.goods[2].img"/>
                        </div>
                    </div>
                    <!--            detail of image-->
                    <div class="img-detail">
                        <div class="img-detail-box">
                                    <span class="title-box">
                                      <span class="title-type"
                                            :style="item.type === 1 ? 'color: #101519' : 'color: #101519'">
                                        <span class="title-type-box"
                                              :style="{ backgroundColor: item.type === 1 ? '#101519' : '#FFDD52'}"
                                              size="medium">
                                          <p class="title-p"
                                             :style="item.type === 1 ? 'color: #FFDD52' : 'color: #101519'">
                                              {{
                                                  item.type === 1
                                                      ? item.status === 1 ? $t('selling') : $t('sold')
                                                      : $t('bought')
                                              }}
                                          </p>
                                        </span>
                                      </span>
                                      {{ item.title }}
                                    </span>
                        </div>
                        <p class="price">
                            {{ currency }} {{ item.price }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="trade_list">
                <div class="itemImg" @click="detail(item.p_id)" v-for="(item, index) in list2" v-bind:key="index">
                    <!--        Item card with 1 image    -->
                    <div class="oneImage" v-if="item.goods.length == 1">
                        <img :src="item.goods[0].img"/>
                    </div>
                    <!--        Item card with 2 image    -->
                    <div class="twoImage" v-if="item.goods.length == 2">
                        <div class="left">
                            <img :src="item.goods[0].img ? item.goods[0].img : defaultGoodsImg"/>
                        </div>
                        <div class="right">
                            <img :src="item.goods[1].img"/>
                        </div>
                    </div>
                    <!--        Item card with 3 image    -->
                    <div class="threeImage" v-if="item.goods.length == 3">
                        <img class="left-img" :src="item.goods[0].img"/>
                        <div class="right-img-box">
                            <img class="right-img" :src="item.goods[1].img"/>
                            <img class="right-img" :src="item.goods[2].img"/>
                        </div>
                    </div>
                    <!--            detail of image-->
                    <div class="img-detail">
                        <div class="img-detail-box">
                                <span class="title-box">
                                  <span class="title-type"
                                        :style="item.type === 1 ? 'color: #101519' : 'color: #101519'">
                                    <span class="title-type-box"
                                          :style="{ backgroundColor: item.type === 1 ? '#101519' : '#FFDD52'}"
                                          size="medium">
                                      <p class="title-p" :style="item.type === 1 ? 'color: #FFDD52' : 'color: #101519'">
                                          {{
                                              item.type === 1
                                                  ? item.status === 1 ? $t('selling') : $t('sold')
                                                  : $t('bought')
                                          }}
                                      </p>
                                    </span>
                                  </span>
                                  {{ item.title }}
                                </span>
                        </div>
                        <p class="price">
                            {{ currency }} {{ item.price }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </van-list>
</template>

<script lang="js">

import {ref} from "vue";
import {order} from "@/api/user.js";

export default {
    name: 'OrderList',
    props: {
        page: Number,
        sort: Number,
        tab: String,
        finished: Boolean,
    },
    setup() {
        const currency = process.env.VUE_APP_CURRENCY_SYMBOL ? process.env.VUE_APP_CURRENCY_SYMBOL : '$';
        const pageSize = process.env.VUE_APP_PAGE_SIZE ? process.env.VUE_APP_PAGE_SIZE : 15;
        const error = ref(false);
        const loading = ref(false);
        const list1 = ref([]);
        const list2 = ref([]);
        return {
            currency,
            pageSize,
            loading,
            error,
            list1,
            list2,
        }
    },
    methods: {
        detail(p_id) {
            this.$router.push({path: `/detail/${p_id}/2`})
        },
        onLoad() {
            let param = {
                page: this.page,
                per_page: this.pageSize,
                status: this.tab,
            };
            // 异步更新数据
            // setTimeout 仅做示例，真实场景中一般为 ajax 请求
            order(param).then(res => {
                if (res.data.trade.data.length > 0) {
                    let t_data = res.data.trade.data;
                    let i = 0;

                    let list1Height = this.getTradeListHeight(0);
                    let list2Height = this.getTradeListHeight(1);
                    let first = 1;
                    if (list2Height < list1Height) {
                        first = 2;
                    }
                    while (i < t_data.length) {
                        if ((first === 1 && this.page > 1) // 首页第一次加载先往第二列放数据（因为有广告），之后再根据高度判断放数据
                            || (first === 1 && this.type === 1) // 搜索页第一次加载先往第一列放数据
                        ) {
                            this.list2.push(t_data[i++]);
                            first = 2;
                        } else {
                            this.list1.push(t_data[i++]);
                            first = 1;
                        }
                        if (i < t_data.length) {
                            if (first === 1) {
                                this.list2.push(t_data[i++]);
                            } else {
                                this.list1.push(t_data[i++]);
                            }
                        }
                    }
                }
                if (res.data.trade.data.length < res.data.trade.per_page) {
                    this.$emit("setFinished", true);
                    this.$emit("setPage", 1);
                } else {
                    this.$emit("setPage", this.page + 1);
                }
                this.loading = false;
            }).catch((error) => {
                console.log('error', error);
                this.error = true;
            });
        },
        getTradeListHeight(num) {
            let totalHeight = 0;
            let children = document.getElementsByClassName("trade_list")[num].children;
            let len = children.length;
            if (len > 0) {
                let child = children[0];
                let i = 0;
                while(i < len) {
                    if (child && child.nodeType === 1) {
                        totalHeight += child.offsetHeight;
                    }
                    i ++;
                    child = children[i];
                }
            }

            return totalHeight;
        },
        setListEmpty () {
            this.list1 = [];
            this.list2 = [];
        },
    }
};
</script>

<style lang="scss" src="@/styles/index.scss"  scoped>
</style>
