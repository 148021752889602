export default {
    "index":"trang đầu",
    "package":"ba lô",
    "me":"của tôi",
    "category":"phân loại",
    "search_product":"tìm kiếm sản phẩm yêu thích",
    "all":"tất cả",
    "buy":"mua",
    "sell":"để bán",
    "account_detail_list":"thông tin chi tiết về tài khoản",
    "go_back":"quay về",
    "balance":"số dư",
    "date_time":"thời gian",
    "operate":"thao tác",
    "detail":"Chi tiết",
    "loading":"đang tải…",
    "load_finished":"không có nhiều hơn nữa",
    "load_error":"yêu cầu thất bại, nhấn để tải lại",
    "operation_failure": "Thao tác thất bại",
    "account_frozen": "Tài khoản đã bị đóng băng",
    "register_failure": "Đăng ký thất bại",
    "please_fill_recharge_amount": "Vui lòng điền số tiền nạp",
    "exceeded_total_withdrawal_limit": "Tổng giới hạn rút tiền của ngày hôm nay đã bị vượt quá",
    "invalid_goods": "Không tồn tại sản phẩm giao dịch",
    "can_not_buy_self_goods": "Sản phẩm của riêng mình không thể mua",

    "search":"tìm kiếm",
    "search_history":"Truy tìm lịch sử",
    "search_hot":"Tìm kiếm nóng",
    "recharge":"nạp tiền ",
    "hot":"hot",
    "new":"mới nhất",
    "trade_deadline":"thời gian dừng giao dịch",
    "trade_post_time":"thời gian phát sinh giao dịch",
    "buy_now":"mua ngay",
    "buy_confirm":"xác nhận mua hàng",
    "recharge_success_msg":"giao dịch sẽ hoàn tất ngay sau khi thanh toán thành công",
    "trade_close_msg":"nếu giao dịch bị hủy/phát sinh hoàn tiền thì thời gian hoàn tiền sẽ là 1 giờ",
    "hour":"giờ",
    "order_amount":"số tiền của đơn hàng",
    "agree":"đồng ý",
    "next":"Tiếp theo",
    "user_service_agreement":"thỏa thuận dịch vụ người dùng",
    "coin_payment":"thanh toán bằng tiền vàng",
    "coin_recharge":"nạp tiền vàng",
    "currency_vietnam":"việt nam đồng",
    "coin":"tiền vàng",
    "recharge_now":"nạp tiền ngay",
    "platform_rules":"quy định của trang giao dịch",
    "got_time":"thời gian bắt đầu",
    "unpack":"đi tới mở hộp",
    "trading":"đang giao dịch",
    "selected":"Đã chọn",
    "piece":"sản phẩm",
    "maximum_selection":"nhiều nhất được chọn",
    "select":"chọn",
    "up_sell":"bán tối đa",
    "select_product":"hãy chọn sản phẩm bán ra",
    "confirm_sale_information":"xác nhận thông tin bán hàng",
    "estimated_total_transaction_price":"tổng giá giao dịch dự kiến của tất cả mặt hàng trong giao dịch này",
    "sale_quantity": "Bán số",
    "enter_sale_quantity": "Xin vui lòng nhập số lượng bán",
    "waring_sell_num_text": "Bài chi tiết: Danh sách các nhà thầu Danh sách các nhà thầu Danh sách các nhà thầu Danh sách các nhà thầu Danh sách các nhà thầu Danh sách các nhà thầu",
    "sum":"tất cả",
    "enter_total_expected_transaction_price":"nhập tổng giá giao dịch dự kiến",
    "rule_description":"mô tả quy tắc",
    "validity_sale_information":"thông tin bán hàng có hiệu lực trong",
    "confirm_sale_price":"giao dịch sẽ hoàn tất ngay sau khi người mua thanh toán, vui lòng xác nhận lại số tiền bán trước khi xác nhận thông tin bán",
    "service_charge_msg":"nhà cung cấp dịch vụ thanh toán sẽ tính {x}% số tiền giao dịch làm phí dịch vụ thanh toán trong quá trình giao dịch",
    "enter_correct_price":"vui lòng nhập gía chính xác",
    "confirm_sale_price_again":"giao dịch sẽ hoàn tất ngay sau khi người mua thanh toán,vui lòng xác nhận kỹ số tiền bán trước khi đồng ý giao dịch bán",
    "repeat_confirm_sale_price":"xác nhận lần 2 về giá giao dịch dự kiến",
    "phone":"số điện thoại",
    "unbind":"chưa đăng ký",
    "bind_phone":"đăng ký số điện thoại",
    "my_account":"tài khoản của tôi",
    "withdraw":"rút",
    "exchage_stone_coin":"đổi thành viên đá nghịch ngợm",
    "stone_coin":"viên đá nghịch ngợm",
    "about_stone_coin":"liên quan tới viên đá nghịch ngợm",
    "my_sale":"kho hàng của tôi",
    "trade_record":"lịch sử giao dịch",
    "service_center":"chăm sóc khách hàng",
    "platform_protocol":"thỏa thuận nền tảng giao dịch",
    "version":"số phiên bản",
    "log_out":"đăng xuất",
    "login":"đăng nhập",
    "sign_in":"đăng ký",
    "your_account":"vào tài khoản của bạn",
    "please_enter":"vui lòng nhập",
    "verification_code":"mã xác nhận",
    "send_verification_code":"gửi mã xác nhận",
    "wait":"Chờ",
    "second":"Chờ",
    "read_msg":"tôi đã đọc và đồng ý ",
    "user_agreement": "điều khoản người dùng",
    "privacy":"điều khoản cá nhân",
    "modify_personal_information":"sửa đổi thông tin cá nhân",
    "save":"lưu",
    "sell_goods":"sản phẩm bán ra",
    "buy_goods":"mua sản phẩm",
    "bank_transfer":"thanh toán bằng tài khoản ngân hàng",
    "exchange_stone_coin":"đổi viên đá nghịch ngợm",
    "check":"kiểm toán",
    "refund":"hoàn tiền",
    "withdrawal_method":"vui lòng lựa chọn phương thức rút ra",
    "bank_transfer_method":"chuyển tiền vào tài khoản ngân hàng",
    "bound":"đã đăng ký",
    "bind":"đăng ký",
    "transfer_bank_account":"chuyển đến tài khoản thẻ ngân hàng",
    "bank_card":"thẻ ngân hàng",
    "current_withdrawable_balance":"số tiền có thể rút ra hiện tại",
    "please_enter_amount":"vui lòng nhập số tiền",
    "minimum":"thấp nhất",
    "attention":"lưu ý",
    "maximum_withdrawal_day":"1 ngày nhiều nhất được rút ra",
    "withdraw_remaining_amount_today":"số tiền còn lại có thể rút ra trong ngày",
    "bank_username":"tên tài khoản thẻ ngân hàng",
    "bank_account":"số thẻ ngân hàng",
    "bank_name":"tên ngân hàng",
    "name":"họ và tên",
    "account_number":"số thẻ",
    "bank":"ngân hàng",
    "money_to_stone_coin":"dùng tiền vàng đổi đá nghịch ngợm",
    "irreversible":"không được đổi lại",
    "remind":"cảnh báo",
    "irreversible_msg":"việc trao đổi này là không được thay đổi, tiền vàng dùng để đổi thành đá nghịch ngợm không thể đổi thành tiền và không rút ra được",
    "explain":"giải thích",
    "explain_1":"đồng tiền vàng đổi đá nghịch ngợm là hoạt động 1 chiều",
    "explain_2":"tức là tiền vàng có thể đổi được đá nghịch ngợm",
    "explain_3":"đá nghịch ngợm không thể đổi thành tiền tiền vàng",
    "explain_4":"đá nghịch ngợm không thể rút ra",
    "current_exchange_quantity":"hiện tại có thể đổi",
    "please_enter_stone_coin_quantity":"vui lòng nhập số lượng đá nghịch ngợm muốn đổi",
    "exchange_quantity_day":"mỗi ngày có thể đổi",
    "exchanged_current":"hiện tại đã đổi",
    "times":"lần",
    "go_to_magic_box":"đi tới hộp ma thuật",
    "my_order":"đơn hàng của tôi",
    "selling":"đang bán ra",
    "complete_transaction":"hoàn thành giao dịch",
    "cancel_trade":"hủy bỏ giao dịch",
    "service_work_time":"thời gian dịch vụ chăm sóc khách hàng",
    "service_vip":"dịch vụ chăm sóc khách hàng VIP",
    "service_common":"dịch vụ chăm sóc khách hàng phổ thông",
    "close":"đóng",
    "confirm":"xác nhận",
    "cancel":"hủy bỏ giao dịch",
    "short_coin":"tiền vàng của bạn không đủ",
    "post_success":"phát hành thành công",
    "enter_confirm_price":"vui lòng nhập giá xác nhận lần 2",
    "different_price":"giá xác nhận lần 2 không khớp với giá lần 1",
    "withdrawal_channel_maintenance":"kênh rút tiền đang được bảo trì",
    "enter_correct_phone_number":"vui lòng nhập số điện thoại chính xác",
    "enter_phone_number":"vui lòng nhập mã số điện thoại",
    "enter_verification_code":"vui lòng nhập mã xác minh",
    "please_agree_user_agreement":"vui lòng đồng ý điều khoản người dùng",
    "verification_code_expired":"mã xác nhận đã hết hạn",
    "please_bind_bank_card":"vui lòng đăng ký tài khoản ngân hàng trước",
    "please_enter_withdrawal_amount":"vui lòng nhập số tiền rút",
    "withdrawal_amount_cannot_be_less":"số tiền rút ra không được dưới",
    "withdrawal_amount_cannot_be_over":"Số tiền rút tiền không thể lớn hơn",
    "less_balance":"số dư của bạn không đủ",
    "withdrawal_day_only":"bạn chỉ có thể rút ra 1 ngày",
    "please_enter_exchange_quantity":"vui lòng nhập số tiền trao đổi",
    "exchange_amount_cannot_be_less":"số tiền trao đổi không được dưới",
    "exchange_day_only":"bạn chỉ có thể đổi 1 ngày",
    "platform_name":"trung tâm đồ cũ",
    "finally_price":"Nguồn",
    "goods_quantity":"Đầu đề",
    "edit_info": "Sửa tài liệu",
    "success": "Thành công",
    "nickname":"Biệt danh",
    "agent_recharge":"Đại lý nạp tiền",
    "system_gift":"Hệ thống miễn phí",
    "recharge_method":"Phương pháp thanh toán",
    "please_login_again":"Xin vui lòng đăng nhập một lần nữa",
    "what_is_naughty_stone":"Là gì viên đá nghịch ngợm?",
    "go_to_magic_box_title":"Hộp nghịch ngợm",
    "go_to_magic_box_content":"hàng vạn hộp thần kỳ, vô vàn điều bất ngờ",
    "pc_alert":"Hãy để thiết bị di động mở trang web",
    "choose_product":"Chọn sản phẩm",
    "recharge_channel_close":"Chức năng nạp tiền đang bảo trì",
    "withdraw_channel_close":"kênh rút tiền đang được bảo trì",
    "exchange_channel_close":"Kênh hoán đổi đang bảo trì",
    "choose_bank":"Xin vui lòng chọn ngân hàng",
    "invalid_recharge_channel":"Kênh thanh toán không tồn tại",
    "recharge_failed":"Thanh toán thất bại",
    "complete": "Thành",
    "failure": "Thất bại",
    "sold": "Đã bán",
    "bought": "Đã mua",
    "account_detail": "Chi tiết tài khoản",
    "exist_withdrawal": "Hiện đã có yêu cầu rút tiền chờ phê duyệt",
    "withdrawal_refund": "Rút tiền thất bại hoàn tiền",
    "manual_deduction": "Hướng dẫn sử dụng khấu trừ",
    "refuse": "Từ chối",
    "exchange_refund": "Hoán đổi thất bại hoàn tiền",
    "arrival_time": "Tiền sẽ đến tài khoản trong vòng 5 phút - 24 giờ",
    "waring": "cảnh giác",
    "waring_price_text": "giá phải bằng hoặc lớn hơn 1",
    "new_guide": "Hướng dẫn Newbie",
    "suggested_transaction_price": "Đề nghị giá bán",
    "current_balance": "Tiền xu hiện tại",
}