<template>
    <div class="shangdai-page">
        <div class="top">
            <router-link to="/" class="title">
                {{ $t('package') }}
            </router-link>
            <van-button @click="btn_click" class="top-left-btn" size="large" center>
        <div class="top-left-btn-text">
           <img class="top-left-btn-icon" src="@/assets/img_icon/marshalling.png" alt>
          {{ $t('unpack') }}
        </div>
            </van-button>
        </div>

        <div class="sort-box">
          <div class="aselect" >
            <div :class="isActive ? 'selector-active' : 'selector'" @click="toggle()">
              <div class="label">
                <span >{{ categoryName }}</span>
              </div>
              <img class="arrow" :class="{ expanded : visible }" :src="dropdownIcon" />
            </div>
            <div :class="{ hidden : !visible, visible }">
              <ul>
                <li v-for="(item,index) in categoryOptions" @click="select(item)" v-bind:key="index">
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
            <span :class="[isNewActive ? 'sort-new-active' : 'sort-new']" @click="indexSort">
            <p>{{ $t('got_time') }}</p>
            <img :src="newImg" alt>
        </span>
        </div>
        <div class="wrapper">
            <van-list
                v-model:loading="loading"
                :finished="finished"
                v-model:error="error"
                :loading-text="$t('loading')"
                :finished-text="$t('load_finished')"
                :error-text="$t('load_error')"
                @load="onLoad"
            >
                <div class="sd-container">
                    <div class="item" v-for="(item) in pack" :key="item">
                        <van-tag class="trading-tag" size="small" round v-show="item.status == 4">
                            <p>{{ $t('trading') }}</p>
                        </van-tag>
                        <div class="image-block">
                            <img class="item-image" :src="defaultImg" v-if="item.goodsInfo.length == 0"/>
                            <img class="item-image" :src="item.goodsInfo.img" v-else/>
                        </div>
                        <div class="description-tag">
                    <span class="description-text" v-if="item.goodsInfo.length == 0">
                        name
                    </span>
                            <span class="description-text" v-else>
                        {{ item.goodsInfo.name }} × {{ item.ItemNum }}
                    </span>
                        </div>
                    </div>
                </div>
            </van-list>
        </div>

        <FooterView/>
    </div>
    <div class="fix-ball" @click="showSellPopup = true">
        <img class="red-ball" src="@/assets/img_icon/Oval.png" alt/>
        <img class="sale-icon" src="@/assets/img_icon/for-sale.png" alt/>
        <p class="sale-text">{{ $t('sell') }}</p>
    </div>
    <div class="video-play-btn" v-show="showBtnVideo">
        <div class="tag-video">
            <img class="play-btn" :src="playIcon" @click="showVideo = true" alt/>
            <img class="close-btn" :src="closeIcon" @click="showBtnVideo = false" alt/>
            <p>{{ $t('new_guide') }}</p>
        </div>
    </div>
    <van-overlay class="overlay-video" :show="showVideo" @click="show = false">
        <div class="video-area">
            <vue3VideoPlay
                width="auto"
                height="auto"
                v-bind="optionsVideo"
                :src="srcVideo"
            />
            <van-button class="btn-close" type="danger" @click="showVideo = false" round>{{ $t('close') }}</van-button>
        </div>
    </van-overlay>
    <!--    pop up-->
    <van-popup
        position="bottom"
        @click-overlay="showSellPopup = false"
        v-model:show="showSellPopup"
        :style="{maxWidth: '100vw', height: '72%', padding: '0', overflow: 'hidden'}"
        closeable
        round
    >
        <div class="popup">
            <p class="popup-title">{{ $t('choose_product') }}</p>
            <div class="grid-image">
                <!--        <van-grid :gutter="10">-->
                <!--          <van-grid-item v-for="(item, index) in pack" :key="item">-->
                <!--              <img class="image" :src="defaultImg" v-if="item.goodsInfo.length == 0" alt/>-->
                <!--              <img class="image" :src="item.goodsInfo.img" v-else alt/>-->
                <!--            <van-checkbox-group class="van-check-group" v-model="checked" :max="5">-->
                <!--              <van-checkbox :name="index" checked-color="#ee0a24" @click="checkedItem(index, item)"></van-checkbox>-->
                <!--            </van-checkbox-group>-->
                <!--          </van-grid-item>-->
                <!--        </van-grid>-->
                <van-checkbox-group class="van-check-group" v-model="checked" :max="sale_max">
                    <div class="item" v-for="(item, index) in pack" :key="item" v-show="item.status != 4" @click="clickItem(index)">
                        <img class="image" :src="defaultImg" v-if="item.goodsInfo.length == 0" alt/>
                        <img class="image" :src="item.goodsInfo.img" v-else alt/>
                        <van-checkbox
                            class="check-box"
                            :name="index"
                            :checked-color="checkedColor"
                            @click="checkedItem(index, item)"
                        >

                        </van-checkbox>
                    </div>
                </van-checkbox-group>
            </div>
            <div class="image-picked-area">
                <p class="picked-quantity">
            <span class="image-picked-length">
                {{ $t('selected') }}
                <span> {{ pickedIndexArray.length }} </span>
                {{ $t('piece') }}
            </span>
                    <span class="picked-rule">{{ $t('maximum_selection') }} {{ sale_max }} {{ $t('piece') }} </span>
                </p>
                <div class="image-picked">
                    <div class="picked">
                        <div class="imagePicked" v-for="(pickedImg, index) in pickedImageArray" v-bind:key="index">
                            <img class="image" :src="pickedImg.goodsInfo.img" alt/>
                        </div>
                    </div>
                    <van-button class="confirm-sale" @click="confirmSale()">
                        <p style="color: #ffffff">{{ $t('confirm') }}</p>
                    </van-button>
                </div>
            </div>
        </div>
    </van-popup>
    <van-loading class="loading-box" type="spinner" color="#000000" size="3rem" v-show="loadingJump"/>
</template>

<script lang="js">
import FooterView from "@/layout/footer/FooterView";
import {Search, Tab, Tabs, Toast} from "vant";
import {ref} from "vue";
import sortDefaultImg from '@/assets/img_icon/sort_default_2x.png';
import sortAscImg from '@/assets/img_icon/sort_asc_2x.png';
import sortDescImg from '@/assets/img_icon/sort_desc_2x.png';
import {backpack, getMagicBoxToken, packCategory} from "@/api/backpack";
import defaultImg from '@/assets/img_icon/product-default.png';
import dropdownIcon from '@/assets/img_icon/dropdown-icon.png';
import playIcon from '@/assets/img_icon/play.png';
import closeIcon from '@/assets/img_icon/close.png';
import { reactive } from "vue";

export default {
    name: "PackageView",
    components: {
        FooterView: FooterView,
        [Search.name]: Search,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [Toast.name]: Toast,
    },
    data() {
        return {
            checkedColor: process.env.VUE_APP_CHECKEDBGCOLOR ? process.env.VUE_APP_CHECKEDBGCOLOR : '',
            defaultImg,
            sortDefaultImg,
            sortAscImg,
            sortDescImg,
            dropdownIcon,
            playIcon,
            closeIcon,
            hotImg: sortDefaultImg,
            newImg: sortDefaultImg,
            isActive: false,
            isNewActive: false,
            magicBoxUrl: process.env.VUE_APP_MAGIC_BOX_URL ? process.env.VUE_APP_MAGIC_BOX_URL : '',
            selectedCategory: {},
            showMenu: false,
            visible: false,
            categoryData: [],
            sort: 0,
            sale_max: 0,
            sale_fee: 0,
            trade_time: 0,
            categoryOptions: [
                {
                    name: this.$t('all'),
                    id: 0
                }
            ]
        }
    },
    setup() {
        const page = 1;
        const pageSize = process.env.VUE_APP_PAGE_SIZE ? process.env.VUE_APP_PAGE_SIZE : 15;
        const tab = ref(0);
        const banner = ([]);
        const searchItem = ref(['']);
        const searchIndex = (value) => {
            searchItem.value = value;
        };
        const showSellPopup = ref(false);
        const checked = ref([]);
        const pack = ref([]);
        const pickedImage = ref(true);
        const pickedIndexArray = ref([]);
        const pickedImageArray = ref({});
        const loading = ref(false);
        const loadingJump = ref(false);
        const error = ref(false);
        const finished = ref(false);
        const categoryId = ref(0);
        const categoryName = ref('');
        const checkedItem = (index, item) => {
            if (checked.value.includes(index) && !pickedIndexArray.value.includes(index)) {
                pickedIndexArray.value.push(index);
                pickedImageArray.value[index] = item;
            } else if (!checked.value.includes(index) && pickedIndexArray.value.includes(index)) {
                pickedIndexArray.value.splice(pickedIndexArray.value.indexOf(index), 1)
                Reflect.deleteProperty(pickedImageArray.value, index);
            }
        };
        const optionsVideo = reactive({
            color: "#409eff",
            title: "",
            muted: false, //静音
            speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"],
            autoPlay: true,
            volume: 0.3,
            controlBtns: [
                "audioTrack",
                "quality",
                "speedRate",
                "volume",
                "fullScreen",
            ],
        });
        const showBtnVideo = ref(false);
        const showVideo = ref(false);
        const srcVideo = ref('');

        return {
            page,
            pageSize,
            searchItem,
            tab,
            searchIndex,
            banner,
            showSellPopup,
            checked,
            pickedImage,
            checkedItem,
            pickedIndexArray,
            pickedImageArray,
            pack,
            finished,
            error,
            loading,
            loadingJump,
            categoryId,
            categoryName,
            optionsVideo,
            showBtnVideo,
            showVideo,
            srcVideo,
        }
    },
    mounted() {
        this.onLoad();
    },
    created() {
        if (this.categoryName == '') {
            this.categoryName = this.categoryOptions[0].name;
        }
        packCategory().then(res => {
            this.categoryData = res.data;
            if (this.categoryData.length > 0) {
                for (let i = 0; i < this.categoryData.length; i++) {
                    let categoryObj = {
                        name: this.categoryData[i].name,
                        id: this.categoryData[i].id,
                    };
                    this.categoryOptions.push(categoryObj);
                }
            }
        }).catch((e) => {
            console.log(e);
        })
    },

    methods: {
        clickItem(index) {
            // 如果已勾选最大数量商品，再点击其他未勾选商品，浮动提示
            if (this.pickedIndexArray.length >= this.sale_max && !this.pickedIndexArray.includes(index)) {
                Toast(this.$t('maximum_selection') + this.sale_max + this.$t('piece'));
            }
        },
        btn_click() {
            window.location.href = this.magicBoxUrl;
            // this.loadingJump = true;
            // getMagicBoxToken().then(res => {
            //     if (res.data) {
            //         window.location.href = this.magicBoxUrl + res.data.access_token;
            //     }
            //     this.loadingJump = false;
            // }).catch((error) => {
            //     console.log('error', error);
            //     this.loadingJump = false;
            // });
        },
        indexSort() {
            switch (this.newImg) {
                case sortDefaultImg:
                    this.newImg = this.sortDescImg;
                    this.isNewActive = true;
                    this.sort = 2;
                    break;
                case sortDescImg:
                    this.newImg = this.sortAscImg;
                    this.isNewActive = true;
                    this.sort = 1;
                    break;
                case sortAscImg:
                    this.newImg = this.sortDefaultImg;
                    this.isNewActive = false;
                    this.sort = 0;
                    break;
            }
            this.isActive = false;
            this.onLoad();
        },
        onLoad() {
            this.finished = false;
            this.pack = [];
            this.loading = true;
            let param = {
                page: this.page,
                limit: this.pageSize,
                c_id: this.categoryId,
                start_time: this.sort,
            };
            // console.log(this.categoryId);
            // console.log(this.sort);
            backpack(param).then(res => {
                this.sale_max = res.data.sale_max;
                this.sale_fee = res.data.sale_fee;
                this.trade_time = res.data.trade_time;
                if (res.data.list.length > 0) {
                    this.pack = res.data.list;
                }
                this.srcVideo = res.data.guide_video;
                this.showBtnVideo = !!this.srcVideo;
                this.finished = true;
            }).catch(() => {
                this.finished = true;
            })
        },
        detail() {
            this.$router.push({path: '/detail'})
        },
        toggle() {
            this.isActive = true;
            this.visible = !this.visible;
            this.isNewActive = false;
            this.newImg = this.sortDefaultImg;
        },
        confirmSale() {
            if (this.pickedIndexArray.length > 0) {
                this.$router.push({
                    path: '/confirmsale',
                    query: {
                        obj: encodeURIComponent(JSON.stringify(this.pickedImageArray)),
                        sale_fee: this.sale_fee,
                        trade_time: this.trade_time,
                    }
                })

            } else {
                console.log('pick item to sale!')
            }
        },
        select(option) {
          this.categoryName = option.name;
          this.categoryId = option.id;
          this.visible = !this.visible;
          this.onLoad();
        }
    },
}
</script>
<style lang="scss" src="@/styles/shangdai.scss" scoped></style>