<template>
    <van-list
        v-model:loading="loading"
        :finished="finished"
        v-model:error="error"
        :loading-text="$t('loading')"
        :finished-text="$t('load_finished')"
        :error-text="$t('load_error')"
        @load="onLoad"
    >
        <div class="container">
            <div class="trade_list">
                <div :class="[adShow ? 'ads' : 'ads-hide']">
                    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#ffffff">
                        <van-swipe-item v-for="(item,index) in ad" :key="index" @click="btn_click(item)">
                            <img :src="item.img"/>
                        </van-swipe-item>
                    </van-swipe>
                </div>
                <div class="itemImg" @click="detail(item.p_id)" v-for="(item, index) in list1" v-bind:key="index">
                    <!--        Item card with 1 image    -->
                    <div class="oneImage" v-if="item.goods.length == 1">
                        <img :src="item.goods[0].img ? item.goods[0].img : defaultGoodsImg"/>
                    </div>
                    <!--        Item card with 2 image    -->
                    <div class="twoImage" v-if="item.goods.length == 2">
                        <div class="left">
                            <img :src="item.goods[0].img ? item.goods[0].img : defaultGoodsImg"/>
                        </div>
                        <div class="right">
                            <img :src="item.goods[1].img"/>
                        </div>
                    </div>
                    <!--        Item card with 3 image    -->
                    <div class="threeImage" v-if="item.goods.length == 3">
                        <img class="left-img" :src="item.goods[0].img ? item.goods[0].img : defaultGoodsImg"/>
                        <div class="right-img-box">
                            <img class="right-img" :src="item.goods[1].img"/>
                            <img class="right-img" :src="item.goods[2].img"/>
                        </div>
                    </div>
                    <!--            detail of image-->
                    <div class="img-detail">
                        <div class="img-detail-box">
                                    <span class="title-box">
                                      <span class="title-type"
                                            :style="item.type === 1 ? 'color: #101519' : 'color: #101519'">
                                        <span class="title-type-box"
                                              :style="{ backgroundColor: item.type === 1 ? '#101519' : '#FFDD52'}"
                                              size="medium">
                                          <p class="title-p"
                                             :style="item.type === 1 ? 'color: #FFDD52' : 'color: #101519'">
                                              {{ item.type === 1 ? $t('sell') : $t('buy') }}
                                          </p>
                                        </span>
                                      </span>
                                      {{ item.title }}
                                    </span>
                        </div>
                        <p class="price">
                            {{ $t('coin') }} {{ item.price }}
                        </p>
                        <div class="user-info">
                            <div class="user-info-left">
                                <img :src="item.avatar ? item.avatar : avatarDefault"/>
                                <span>{{ item.name }}</span>
                            </div>
                            <span class="user-info-right">{{ item.created_at }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="trade_list">
                <div class="itemImg" @click="detail(item.p_id)" v-for="(item, index) in list2" v-bind:key="index">
                    <!--        Item card with 1 image    -->
                    <div class="oneImage" v-if="item.goods.length == 1">
                        <img :src="item.goods[0].img ? item.goods[0].img : defaultGoodsImg"/>
                    </div>
                    <!--        Item card with 2 image    -->
                    <div class="twoImage" v-if="item.goods.length == 2">
                        <div class="left">
                            <img :src="item.goods[0].img ? item.goods[0].img : defaultGoodsImg"/>
                        </div>
                        <div class="right">
                            <img :src="item.goods[1].img"/>
                        </div>
                    </div>
                    <!--        Item card with 3 image    -->
                    <div class="threeImage" v-if="item.goods.length == 3">
                        <img class="left-img" :src="item.goods[0].img ? item.goods[0].img : defaultGoodsImg"/>
                        <div class="right-img-box">
                            <img class="right-img" :src="item.goods[1].img"/>
                            <img class="right-img" :src="item.goods[2].img"/>
                        </div>
                    </div>
                    <!--            detail of image-->
                    <div class="img-detail">
                        <div class="img-detail-box">
                                <span class="title-box">
                                  <span class="title-type"
                                        :style="item.type === 1 ? 'color: #101519' : 'color: #101519'">
                                    <span class="title-type-box"
                                          :style="{ backgroundColor: item.type === 1 ? '#101519' : '#FFDD52'}"
                                          size="medium">
                                      <p class="title-p" :style="item.type === 1 ? 'color: #FFDD52' : 'color: #101519'">
                                          {{ item.type === 1 ? $t('sell') : $t('buy') }}
                                      </p>
                                    </span>
                                  </span>
                                  {{ item.title }}
                                </span>
                        </div>
                        <p class="price">
                            {{ $t('coin') }} {{ item.price }}
                        </p>
                        <div class="user-info">
                            <div class="user-info-left">
                                <img :src="item.avatar ? item.avatar : avatarDefault"/>
                                <span>{{ item.name }}</span>
                            </div>
                            <span class="user-info-right">{{ item.created_at }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </van-list>
</template>

<script lang="js">

import {ref} from "vue";
import {index} from "@/api";
import adDefaultImg from '@/assets/img_icon/freshippo.png';
import avatarDefault from '@/assets/img_icon/avatar_default.png';
import defaultGoodsImg from '@/assets/img_icon/default_goods_img.png';

export default {
    name: 'IndexList',
    props: {
        page: Number,
        sort: Number,
        tab: Number,
        adShow: Boolean,
        finished: Boolean,
        category: Number,
        name: String,
        goodsId: String,
        type: Number,
    },
    data() {
        return {
            adDefaultImg, avatarDefault, defaultGoodsImg,
            ad: [{
                img: this.adDefaultImg,
                jump: 0,
                jump_type: 1,
                jump_url: '',
            }],
          switch_recharge: 0,
        };
    },
    setup() {
        const currency = process.env.VUE_APP_CURRENCY_SYMBOL ? process.env.VUE_APP_CURRENCY_SYMBOL : '$';
        const pageSize = process.env.VUE_APP_PAGE_SIZE ? process.env.VUE_APP_PAGE_SIZE : 15;

        const error = ref(false);
        const loading = ref(false);
        const list1 = ref([]);
        const list2 = ref([]);
        const width = ref('');
        const height = ref('');
        const srcVideo = ref('');
        return {
            currency,
            pageSize,
            loading,
            error,
            list1,
            list2,
            width,
            height,
            srcVideo,
        }
    },
    // created() {
    //     console.log(this.getWidth());
    // },
    methods: {
        detail(id) {
            this.$router.push({path: `/detail/${id}/1`})
        },
        btn_click(data) {
            if (data.jump == 1) {
                window.open(data.jump_url, "_blank");
            }
        },
        onLoad() {
            // 异步更新数据
            let param = {
                page: this.page,
                per_page: this.pageSize,
                sort: this.sort,
                type: this.tab,
                title: this.name,
                g_id: this.goodsId,
                c_id: this.category,
            };

            index(param).then(res => {

                this.ad = res.data.banner;
                this.$emit("setAdShow", true);
                this.switch_recharge = res.data.switch_recharge;
                this.srcVideo = res.data.guide_video;
                this.$emit("setSwitchRecharge", this.switch_recharge);
                this.$emit("setSrcVideo", this.srcVideo);
                if (res.data.trade.data.length > 0) {
                    let t_data = res.data.trade.data;
                    let i = 0;

                    let list1Height = this.getTradeListHeight(0);
                    let list2Height = this.getTradeListHeight(1);
                    let first = 1;
                    if (list2Height < list1Height) {
                        first = 2;
                    }
                    while (i < t_data.length) {
                        if ((first === 1 && this.page > 1) // 首页第一次加载先往第二列放数据（因为有广告），之后再根据高度判断放数据
                            || (first === 1 && this.type === 1) // 搜索页第一次加载先往第一列放数据
                        ) {
                            this.list1.push(t_data[i++]);
                            first = 1;
                        } else {
                            this.list2.push(t_data[i++]);
                            first = 2;
                        }
                        if (i < t_data.length) {
                            if (first === 1) {
                                this.list2.push(t_data[i++]);
                            } else {
                                this.list1.push(t_data[i++]);
                            }
                        }
                    }
                }
                if (res.data.trade.data.length < res.data.trade.per_page) {
                    this.$emit("setFinished", true);
                    this.$emit("setPage", 1);
                } else {
                    this.$emit("setPage", this.page + 1);
                }
                this.loading = false;
            }).catch((error) => {
                console.log('error', error);
                this.error = true;
            });
        },

        getTradeListHeight(num) {
            let totalHeight = 0;
            let children = document.getElementsByClassName("trade_list")[num].children;
            let len = children.length;
            if (len > 0) {
                let child = children[0];
                let i = 0;
                while(i < len) {
                    if (child && child.nodeType === 1) {
                        totalHeight += child.offsetHeight;
                    }
                    i ++;
                    child = children[i];
                }
            }

            return totalHeight;
        },
        setListEmpty () {
            this.list1 = [];
            this.list2 = [];
        },
    },
};
</script>

<style lang="scss" src="@/styles/index.scss"  scoped>
</style>
